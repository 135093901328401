import styled from "@emotion/styled"
import { LobbyAvailableFilters } from "api"
import Link from "next/link"
import { Desktop, NewText, RedNoteIcon } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { JobsObject } from "utils"
import { CareerLobbyMessages } from "../Careers"
import JobCard from "./JobCard"
import JobSkeleton from "./JobSkeleton"

const TextBox = styled.div`
  display: flex;
  gap: 16px;
  height: 20px;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: flex-start;
  }
`
export const Divider = styled.div`
  width: 1px; /* or height for horizontal dividers */
  background-color: #333; /* Divider color */
  height: 20px;
  border-radius: 8px;
`
const JobsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`
const JobCards = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  row-gap: 24px;
  column-gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
  }
`

const StyledNoResultDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
    gap: 16px;
    flex-direction: row;
  }
`

const StyledLink = styled(Link)`
  text-decoration-color: black;
  color: black;
`

type Props = {
  jobs: JobsObject["items"]
  isFetching: boolean
  isFetchingNextPage: boolean
  locale: string
  messages: CareerLobbyMessages
  totalCount: number
  filters: LobbyAvailableFilters
  isGroupWebsite?: boolean
}
function JobsDisplay({
  jobs,
  isFetching,
  isFetchingNextPage,
  locale,
  messages,
  totalCount,
  filters,
  isGroupWebsite,
}: Props) {
  const numberText = messages.viewResults.includes("№")
    ? `${messages.viewResults.replace("№", String(totalCount))}`
    : `${totalCount} ${messages.viewResults}`

  return (
    <JobsBox>
      {jobs.length > 0 ? (
        <TextBox>
          <NewText
            component={"span"}
            desktopFontSize={16}
            color={colors.black}
            desktopFontWeight={600}
            mobileFontSize={14}
          >
            {messages.jobsBoard}
          </NewText>
          <Desktop>
            <Divider />
          </Desktop>
          <NewText component={"span"} desktopFontSize={16} color={colors.black} mobileFontSize={14}>
            {totalCount && numberText}
          </NewText>
        </TextBox>
      ) : (
        <StyledNoResultDiv>
          <RedNoteIcon />
          {isGroupWebsite ? (
            <NewText
              component={"span"}
              desktopFontSize={18}
              mobileFontSize={16}
              color={colors.black}
              desktopFontWeight={600}
            >
              {locale === "he"
                ? "לצערנו, נראה שאין כרגע משרות פתוחות המתאימות לחיפוש שביצעת. המשרות מתעדכנות מעת לעת, מזמינים אותך לבקר אותנו שוב בהמשך וגם לעקוב אחרינו בעמודי הפייסבוק והאינסטגרם של קבוצת אשטרום."
                : "Unfortunately, we do not have any open positions matching your profile currently. However, we regularly update our job openings, so we encourage you to check back with us later. In the meantime, you can stay connected and get the latest updates by following us on our Facebook and Instagram pages."}{" "}
            </NewText>
          ) : (
            <NewText
              component={"span"}
              desktopFontSize={18}
              mobileFontSize={16}
              color={colors.black}
              desktopFontWeight={600}
            >
              {locale === "he"
                ? `לצערנו, נראה שאין כרגע משרות פתוחות המתאימות לחיפוש שביצעת, אבל יתכן שיש לנו עבורך משרה מתאימה בעמוד המשרות של קבוצת אשטרום, בלחיצה `
                : "Unfortunately, there are currently no open positions matching your search, but we may have a suitable position for you on the Ashtrom Group's jobs page. Click"}

              <StyledLink
                href="https://www.ashtrom.co.il/career"
                target="_blank"
                rel="noopener noreferrer"
              >
                {locale === "he" ? " כאן" : "here"}
              </StyledLink>

              {locale === "he"
                ? ` / כניסה לעמוד הקריירה של קבוצת אשטרום.`
                : "or visit our career page to explore further."}
            </NewText>
          )}
        </StyledNoResultDiv>
      )}

      <JobCards>
        {isFetching && !isFetchingNextPage ? (
          <>
            {[...Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </>
        ) : null}
        {!isFetching || (isFetching && isFetchingNextPage)
          ? jobs.map((el, index) => (
              <JobCard
                key={el?.orderId || index}
                job={el}
                locale={locale}
                messages={messages}
                filters={filters}
              />
            ))
          : null}

        {isFetchingNextPage ? (
          <>
            {[...Array(6)].map((_, index) => (
              <JobSkeleton key={index} />
            ))}
          </>
        ) : null}
      </JobCards>
    </JobsBox>
  )
}

export default JobsDisplay
